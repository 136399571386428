import defaultAttributes from '../defaultAttributes';

const ThermometerSnowflake = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 12h10' }],
    ['path', { d: 'M9 4v16' }],
    ['path', { d: 'm3 9 3 3-3 3' }],
    ['path', { d: 'M12 6 9 9 6 6' }],
    ['path', { d: 'm6 18 3-3 1.5 1.5' }],
    ['path', { d: 'M18 2a2 2 0 0 0-2 2v10.5a4 4 0 1 0 4 0V4a2 2 0 0 0-2-2z' }],
  ],
];

export default ThermometerSnowflake;
