import defaultAttributes from '../defaultAttributes';

const Inspect = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M19 11V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h7' },
    ],
    ['path', { d: 'm12 12 4.166 10 1.48-4.355L22 16.166 12 12z' }],
    ['path', { d: 'm18 18 3 3' }],
  ],
];

export default Inspect;
