import defaultAttributes from '../defaultAttributes';

const Video = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M22 8L16 12L22 16V8Z' }],
    ['rect', { x: '2', y: '6', width: '14', height: '12', rx: '2', ry: '2' }],
  ],
];

export default Video;
