import defaultAttributes from '../defaultAttributes';

const Ticket = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M3 9V7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v2M3 15v2a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-2',
      },
    ],
    ['path', { d: 'M21 15a3 3 0 1 1 0-6' }],
    ['path', { d: 'M3 15a3 3 0 1 0 0-6' }],
    ['path', { d: 'M13 5v2' }],
    ['path', { d: 'M13 17v2' }],
    ['path', { d: 'M13 11v2' }],
  ],
];

export default Ticket;
