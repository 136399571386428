import defaultAttributes from '../defaultAttributes';

const BarChart4 = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 2v20h20' }],
    ['path', { d: 'M12 18V9' }],
    ['path', { d: 'M17 18V4' }],
    ['path', { d: 'M7 18v-4' }],
  ],
];

export default BarChart4;
