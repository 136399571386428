import defaultAttributes from '../defaultAttributes';

const Truck = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M10 17h4V5H2v12h3m15 0h2v-3.34a4 4 0 0 0-1.17-2.83L19 9h-5v8h1' },
    ],
    ['circle', { cx: '7.5', cy: '17.5', r: '2.5' }],
    ['circle', { cx: '17.5', cy: '17.5', r: '2.5' }],
  ],
];

export default Truck;
