import defaultAttributes from '../defaultAttributes';

const Tag = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M2 12V2h10l9.44 9.44a2 2 0 0 1 0 2.82l-7.18 7.18a2 2 0 0 1-2.82 0L2 12Z',
      },
    ],
    ['path', { d: 'M7 7h.01' }],
  ],
];

export default Tag;
