import defaultAttributes from '../defaultAttributes';

const Watch = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '12', r: '6' }],
    ['polyline', { points: '12 10 12 12 13 13' }],
    [
      'path',
      {
        d:
          'M16.13 7.66l-.81-4.05a2 2 0 0 0-2-1.61H10.64a2 2 0 0 0-2 1.61L7.86 7.66',
      },
    ],
    [
      'path',
      { d: 'M7.88 16.36l.8 4a2 2 0 0 0 2 1.61h2.72a2 2 0 0 0 2-1.61l.81-4.05' },
    ],
  ],
];

export default Watch;
