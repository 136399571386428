import defaultAttributes from '../defaultAttributes';

const PackageX = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M21 10V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l2-1.14',
      },
    ],
    ['path', { d: 'M16.5 9.4 7.55 4.24' }],
    ['path', { d: 'M3.29 7 12 12m0 0 8.71-5M12 12v10' }],
    ['path', { d: 'm17 13 5 5m-5 0 5-5' }],
  ],
];

export default PackageX;
