import defaultAttributes from '../defaultAttributes';

const CloudOff = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm2 2 20 20' }],
    ['path', { d: 'M10 5a7 7 0 0 1 5.7 5h1.8a4.5 4.5 0 0 1 4 6.5' }],
    ['path', { d: 'M18.8 18.8c-.4.2-.8.2-1.3.2H9A7 7 0 0 1 5.8 5.8' }],
  ],
];

export default CloudOff;
