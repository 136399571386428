import defaultAttributes from '../defaultAttributes';

const TimerOff = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 2h4' }],
    [
      'path',
      { d: 'M7.43 7.433A8 8 0 0 1 18.566 18.57M4.582 11A8 8 0 0 0 15 21.419' },
    ],
    ['path', { d: 'm2 2 20 20' }],
    ['path', { d: 'M12 12v-2' }],
  ],
];

export default TimerOff;
