import defaultAttributes from '../defaultAttributes';

const Gitlab = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M22 13.29l-3.33-10a.42.42 0 0 0-.14-.18A.38.38 0 0 0 18.31 3a.39.39 0 0 0-.23.07.42.42 0 0 0-.14.18L15.68 9.92H8.32L6.1 3.26A.42.42 0 0 0 6 3.08.38.38 0 0 0 5.74 3a.39.39 0 0 0-.23.07.42.42 0 0 0-.14.18L2 13.29a.74.74 0 0 0 .27.83L12 21l9.69-6.88a.71.71 0 0 0 .31-.83Z',
      },
    ],
  ],
];

export default Gitlab;
