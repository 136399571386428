import defaultAttributes from '../defaultAttributes';

const Eraser = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 20H7L3 16C2.5 15.5 2.5 14.5 3 14L13 4L20 11L11 20' }],
    ['path', { d: 'M6 11L13 18' }],
  ],
];

export default Eraser;
