import defaultAttributes from '../defaultAttributes';

const Paperclip = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84L9.41 17.41a2 2 0 0 1-2.83-2.83L15.07 6.1',
      },
    ],
  ],
];

export default Paperclip;
