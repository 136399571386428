import defaultAttributes from '../defaultAttributes';

const EyeOff = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm9.88 9.88a3 3 0 1 0 4.24 4.24' }],
    [
      'path',
      {
        d:
          'M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68',
      },
    ],
    [
      'path',
      {
        d:
          'm6.61 6.61c-1.989 1.355-3.58 3.215-4.61 5.39 0 0 3 7 10 7 1.916 0.0051 3.791-0.5549 5.39-1.61',
      },
    ],
    ['line', { x1: '2', y1: '2', x2: '22', y2: '22' }],
  ],
];

export default EyeOff;
