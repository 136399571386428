import defaultAttributes from '../defaultAttributes';

const AlertTriangle = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M21.73 18l-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21H20A2 2 0 0 0 21.73 18Z',
      },
    ],
    ['line', { x1: '12', y1: '9', x2: '12', y2: '13' }],
    ['line', { x1: '12', y1: '17', x2: '12.01', y2: '17' }],
  ],
];

export default AlertTriangle;
