import defaultAttributes from '../defaultAttributes';

const CloudRainWind = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 16.2A4.5 4.5 0 0 0 17.5 8h-1.8A7 7 0 1 0 4 14.9' }],
    ['path', { d: 'm9.2 22 3-7' }],
    ['path', { d: 'm9 13-3 7' }],
    ['path', { d: 'm17 13-3 7' }],
  ],
];

export default CloudRainWind;
