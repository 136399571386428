import defaultAttributes from '../defaultAttributes';

const FilePlus2 = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4' }],
    ['path', { d: 'M14 2v6h6' }],
    ['path', { d: 'M3 15h6' }],
    ['path', { d: 'M6 12v6' }],
  ],
];

export default FilePlus2;
