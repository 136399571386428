import defaultAttributes from '../defaultAttributes';

const Contact = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M17 18a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2' }],
    ['rect', { x: '3', y: '4', width: '18', height: '18', rx: '2' }],
    ['circle', { class: 'cls-2', cx: '12', cy: '10', r: '2' }],
    ['line', { x1: '8', y1: '2', x2: '8', y2: '4' }],
    ['line', { x1: '16', y1: '2', x2: '16', y2: '4' }],
  ],
];

export default Contact;
