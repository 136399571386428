import defaultAttributes from '../defaultAttributes';

const Triangle = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M21.73 18l-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21H20A2 2 0 0 0 21.73 18Z',
      },
    ],
  ],
];

export default Triangle;
