import defaultAttributes from '../defaultAttributes';

const Twitter = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M22 4c0 0-0.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6c2.2 0.1 4.4-0.6 6-2C3 15.5 0.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4 c-0.9-4.2 4-6.6 7-3.8C20.1 5.2 22 4 22 4z',
      },
    ],
  ],
];

export default Twitter;
