import defaultAttributes from '../defaultAttributes';

const PhoneCall = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M14.05 6A5 5 0 0 1 18 10m-3.95-8a9 9 0 0 1 8 7.94m0 7v3a2 2 0 0 1-2 2h-.19a19.79 19.79 0 0 1-8.63-3.07 19.52 19.52 0 0 1-6-6 19.82 19.82 0 0 1-3.11-8.69A2 2 0 0 1 3.93 2h3.18a2 2 0 0 1 2 1.72 13 13 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 13 13 0 0 0 2.81.7A2 2 0 0 1 22 16.92z',
      },
    ],
  ],
];

export default PhoneCall;
