import defaultAttributes from '../defaultAttributes';

const FileSearch = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v3' }],
    ['path', { d: 'M14 2v6h6' }],
    ['path', { d: 'M5 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' }],
    ['path', { d: 'm9 18-1.5-1.5' }],
  ],
];

export default FileSearch;
