import defaultAttributes from '../defaultAttributes';

const Accessibility = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '16', cy: '4', r: '1' }],
    ['path', { d: 'M18 19L19 12L13.13 12.94' }],
    ['path', { d: 'M5 8L8 5L13.5 8L11.29 11.1' }],
    [
      'path',
      {
        d:
          'M4.24 14.48C4.05 15.06 3.97 15.68 4.01 16.32C4.19 19.08 6.57 21.17 9.32 20.99C9.97 20.95 10.57 20.79 11.12 20.53',
      },
    ],
    [
      'path',
      {
        d:
          'M13.76 17.52C13.95 16.94 14.03 16.32 13.99 15.68C13.81 12.92 11.43 10.83 8.68 11.01C8.03 11.05 7.43 11.21 6.88 11.47',
      },
    ],
  ],
];

export default Accessibility;
