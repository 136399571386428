import defaultAttributes from '../defaultAttributes';

const Cloudy = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M17.5 21a4.5 4.5 0 1 0 0-9h-1.8A7 7 0 1 0 9 21h8.5z' }],
    [
      'path',
      {
        d: 'M22 10c0-1.5-1.5-3-3.5-3H17c-.7-2.3-2.9-4-5.4-4-2.7 0-5 2-5.5 4.5',
      },
    ],
  ],
];

export default Cloudy;
