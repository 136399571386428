import defaultAttributes from '../defaultAttributes';

const FolderMinus = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z',
      },
    ],
    ['line', { x1: '9', y1: '14', x2: '15', y2: '14' }],
  ],
];

export default FolderMinus;
