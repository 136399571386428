import defaultAttributes from '../defaultAttributes';

const Eye = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 12S5 5 12 5s10 7 10 7-3 7-10 7S2 12 2 12Z' }],
    ['circle', { cx: '12', cy: '12', r: '3' }],
  ],
];

export default Eye;
