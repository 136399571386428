import defaultAttributes from '../defaultAttributes';

const FolderOpen = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'm6 17 2-5h14l-3 8a2 2 0 0 1-2 1H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h7a2 2 0 0 1 2 2v4',
      },
    ],
  ],
];

export default FolderOpen;
