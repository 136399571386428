import defaultAttributes from '../defaultAttributes';

const Youtube = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M12 19c-2.3 0-6.4-0.2-8.1-0.6c-0.7-0.2-1.2-0.7-1.4-1.4C2.2 15.9 2 13.6 2 12c0-1.6 0.2-3.9 0.5-5 c0.2-0.7 0.7-1.2 1.4-1.4C5.6 5.2 9.7 5 12 5c2.3 0 6.4 0.2 8.1 0.6c0.7 0.2 1.2 0.7 1.4 1.4c0.3 1.1 0.5 3.4 0.5 5 c0 1.6-0.2 3.9-0.5 5c-0.2 0.7-0.7 1.2-1.4 1.4C18.4 18.8 14.3 19 12 19C12 19 12 19 12 19z',
      },
    ],
    ['polygon', { points: '10 15 15 12 10 9' }],
  ],
];

export default Youtube;
