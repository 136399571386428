import defaultAttributes from '../defaultAttributes';

const Maximize = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3',
      },
    ],
  ],
];

export default Maximize;
