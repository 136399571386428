import defaultAttributes from '../defaultAttributes';

const LightbulbOff = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M9 18h6' }],
    ['path', { d: 'M10 22h4' }],
    ['path', { d: 'm2 2 20 20' }],
    [
      'path',
      {
        d:
          'M8.91 14a4.61 4.61 0 0 0-1.41-2.5C6.23 10.23 6 9 6 8a6 6 0 0 1 .084-1M9 2.804A6 6 0 0 1 18 8a4.651 4.651 0 0 1-1.031 3',
      },
    ],
  ],
];

export default LightbulbOff;
