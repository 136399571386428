import defaultAttributes from '../defaultAttributes';

const CloudLightning = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M17.5 17a4.5 4.5 0 1 0 0-9h-1.8a7 7 0 1 0-10.3 8' }],
    ['path', { d: 'm12 12-3 5h5l-3 5' }],
  ],
];

export default CloudLightning;
