import defaultAttributes from '../defaultAttributes';

const BarChartHorizontal = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 2V22H22' }],
    ['path', { d: 'M6 17L15 17' }],
    ['path', { d: 'M6 12L20 12' }],
    ['path', { d: 'M6 7L10 7' }],
  ],
];

export default BarChartHorizontal;
