import defaultAttributes from '../defaultAttributes';

const Ruler = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm16 2 6 6L8 22l-6-6L16 2' }],
    ['path', { d: 'm7.5 10.5 2 2' }],
    ['path', { d: 'm10.5 7.5 2 2' }],
    ['path', { d: 'm13.5 4.5 2 2' }],
    ['path', { d: 'm4.5 13.5 2 2' }],
  ],
];

export default Ruler;
