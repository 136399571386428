import defaultAttributes from '../defaultAttributes';

const NavigationOff = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M8.43 8.43 3 11l8 2 2 8 2.57-5.43' }],
    ['path', { d: 'M17.39 11.73 22 2 12.27 6.61' }],
    ['line', { x1: '2', y1: '2', x2: '22', y2: '22' }],
  ],
];

export default NavigationOff;
