import defaultAttributes from '../defaultAttributes';

const ClipboardCopy = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M16 4h2a2 2 0 0 1 2 2v4M8 4H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2',
      },
    ],
    ['rect', { x: '8', y: '2', width: '8', height: '4', rx: '1', ry: '1' }],
    ['path', { d: 'M21 14H11' }],
    ['path', { d: 'm15 10-4 4 4 4' }],
  ],
];

export default ClipboardCopy;
