import defaultAttributes from '../defaultAttributes';

const Minimize = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3',
      },
    ],
  ],
];

export default Minimize;
