import defaultAttributes from '../defaultAttributes';

const Pipette = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 22l1-1H6l9-9' }],
    ['path', { d: 'M3 21V18l9-9' }],
    [
      'path',
      {
        d:
          'M21.37 5.63a2.12 2.12 0 0 0-3-3L15.58 5.42a1.05 1.05 0 0 1-1.5 0 1.07 1.07 0 0 0-1.5 0L11.25 6.75a1.07 1.07 0 0 0 0 1.5l4.5 4.5a1.07 1.07 0 0 0 1.5 0l1.33-1.33a1.07 1.07 0 0 0 0-1.5 1.05 1.05 0 0 1 0-1.5Z',
      },
    ],
  ],
];

export default Pipette;
