import defaultAttributes from '../defaultAttributes';

const CloudMoon = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M13.63 22A3.3 3.3 0 0 0 17 18.79a3.3 3.3 0 0 0-3.38-3.22h-1.34A5.23 5.23 0 0 0 7.25 12 5.13 5.13 0 0 0 2 17c0 2.76 2.35 5 5.25 5h6.38z',
      },
    ],
    [
      'path',
      {
        d:
          'M9.95 9a6.13 6.13 0 0 1 5.5-5.18 4.77 4.77 0 0 0 6.67 6.67A6.13 6.13 0 0 1 19.46 15',
      },
    ],
  ],
];

export default CloudMoon;
