import defaultAttributes from '../defaultAttributes';

const Mic = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 2A3 3 0 0 0 9 5v7a3 3 0 0 0 6 0V5A3 3 0 0 0 12 2Z' }],
    ['path', { d: 'M19 10v2A7 7 0 0 1 5 12V10' }],
    ['line', { x1: '12', y1: '19', x2: '12', y2: '22' }],
  ],
];

export default Mic;
