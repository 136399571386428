import defaultAttributes from '../defaultAttributes';

const Delete = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 5H9L2 12l7 7H20a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2Z' }],
    ['line', { x1: '18', y1: '9', x2: '12', y2: '15' }],
    ['line', { x1: '12', y1: '9', x2: '18', y2: '15' }],
  ],
];

export default Delete;
