import defaultAttributes from '../defaultAttributes';

const Sun = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '12', r: '4' }],
    ['path', { d: 'M12 2v2' }],
    ['path', { d: 'M12 20v2' }],
    ['path', { d: 'm5 5 1.5 1.5' }],
    ['path', { d: 'M17.5 17.5 19 19' }],
    ['path', { d: 'M2 12h2' }],
    ['path', { d: 'M20 12h2' }],
    ['path', { d: 'm5 19 1.5-1.5' }],
    ['path', { d: 'M17.5 6.5 19 5' }],
  ],
];

export default Sun;
