import defaultAttributes from '../defaultAttributes';

const UploadCloud = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 16.2A4.5 4.5 0 0 0 17.5 8h-1.8A7 7 0 1 0 4 14.9' }],
    ['path', { d: 'M12 12v9' }],
    ['path', { d: 'm16 16-4-4-4 4' }],
  ],
];

export default UploadCloud;
