import defaultAttributes from '../defaultAttributes';

const CloudFog = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 16.2A4.5 4.5 0 0 0 17.5 8h-1.8A7 7 0 1 0 4 14.9' }],
    ['path', { d: 'M16 17H7' }],
    ['path', { d: 'M17 21H9' }],
  ],
];

export default CloudFog;
