import defaultAttributes from '../defaultAttributes';

const Sunset = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 10V2' }],
    ['path', { d: 'm5.2 11.2 1.4 1.4' }],
    ['path', { d: 'M2 18h2' }],
    ['path', { d: 'M20 18h2' }],
    ['path', { d: 'm17.4 12.6 1.4-1.4' }],
    ['path', { d: 'M22 22H2' }],
    ['path', { d: 'm16 6-4 4-4-4' }],
    ['path', { d: 'M16 18a4 4 0 0 0-8 0' }],
  ],
];

export default Sunset;
