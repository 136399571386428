import defaultAttributes from '../defaultAttributes';

const Scale = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm16 16 3-8 3.001 8A5.002 5.002 0 0 1 16 16z' }],
    ['path', { d: 'm2 16 3-8 3.001 8A5.002 5.002 0 0 1 2 16z' }],
    ['path', { d: 'M7 21h10' }],
    ['path', { d: 'M12 3v18' }],
    ['path', { d: 'M3 7h2c2 0 5-1 7-2 2 1 5 2 7 2h2' }],
  ],
];

export default Scale;
