import defaultAttributes from '../defaultAttributes';

const PowerOff = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M18.36 6.64A9 9 0 0 1 20.77 15M5.63 6.64A9 9 0 1 0 18.706 19' },
    ],
    ['path', { d: 'M12 2v4' }],
    ['path', { d: 'm2 2 20 20' }],
  ],
];

export default PowerOff;
