import defaultAttributes from '../defaultAttributes';

const MapPin = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 10c0 6-8 12-8 12S4 16 4 10a8 8 0 0 1 16 0Z' }],
    ['circle', { cx: '12', cy: '10', r: '3' }],
  ],
];

export default MapPin;
