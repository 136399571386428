import defaultAttributes from '../defaultAttributes';

const Archive = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M20 9v9a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9m16-5H4a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm-10 9h4',
      },
    ],
  ],
];

export default Archive;
