import defaultAttributes from '../defaultAttributes';

const CloudHail = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 16.2A4.5 4.5 0 0 0 17.5 8h-1.8A7 7 0 1 0 4 14.9' }],
    ['path', { d: 'M16 14v2' }],
    ['path', { d: 'M8 14v2' }],
    ['path', { d: 'M16 20h0' }],
    ['path', { d: 'M8 20h0' }],
    ['path', { d: 'M12 16v2' }],
    ['path', { d: 'M12 22h0' }],
  ],
];

export default CloudHail;
