import defaultAttributes from '../defaultAttributes';

const Folder = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z',
      },
    ],
  ],
];

export default Folder;
