import defaultAttributes from '../defaultAttributes';

const PackageSearch = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M21 10V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l2-1.14',
      },
    ],
    ['path', { d: 'M16.5 9.4 7.55 4.24' }],
    ['path', { d: 'M12 12v10M3.29 7 12 12 3.29 7zM12 12l8.71-5L12 12z' }],
    ['circle', { cx: '18.5', cy: '15.5', r: '2.5' }],
    ['path', { d: 'M20.27 17.27 22 19' }],
  ],
];

export default PackageSearch;
