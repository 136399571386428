import defaultAttributes from '../defaultAttributes';

const Verified = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M12 3a3.6 3.6 0 0 0-3.05 1.68 3.6 3.6 0 0 0-.9-.1 3.6 3.6 0 0 0-2.42 1.06 3.6 3.6 0 0 0-.94 3.32A3.6 3.6 0 0 0 3 12a3.6 3.6 0 0 0 1.69 3.05 3.6 3.6 0 0 0 .95 3.32 3.6 3.6 0 0 0 3.35.96A3.6 3.6 0 0 0 12 21a3.6 3.6 0 0 0 3.04-1.67 3.6 3.6 0 0 0 4.3-4.3A3.6 3.6 0 0 0 21 12a3.6 3.6 0 0 0-1.67-3.04v0a3.6 3.6 0 0 0-4.3-4.3A3.6 3.6 0 0 0 12 3z',
      },
    ],
    ['path', { d: 'm15 10-4 4' }],
    ['path', { d: 'm9 12 2 2' }],
  ],
];

export default Verified;
