import defaultAttributes from '../defaultAttributes';

const AlarmClockOff = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M19.94 14A8 8 0 0 0 10 5.25m8.13 12.89A8 8 0 1 1 6.87 6.86' },
    ],
    ['path', { d: 'm22 6-3-3' }],
    ['path', { d: 'm6 19-2 2' }],
    ['path', { d: 'm2 2 20 20' }],
    ['path', { d: 'M4 4 2 6' }],
  ],
];

export default AlarmClockOff;
